import { Button, Modal } from "rsuite"

const GenericModal = (props) => {
    return  <Modal className="genericModal" backdrop={true} keyboard={false} show={props.modalState}
                onHide={props.closeModal}>
                <Modal.Header>
                    <Modal.Title>{props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{width: '100%', overflowX: 'hidden'}}>
                    {props.children}
                </Modal.Body>
                <Modal.Footer>
                    { props.handleApply ? <Button loading={props.loading || false} onClick={props.handleApply || props.closeModal} appearance="primary" color={props.color}>{props.applyLabel || 'Aplicar'}</Button> : null }
                    { props.closeModal ? <Button onClick={props.closeModal} appearance="subtle">{props.closeLabel || 'Cancelar'}</Button> : null }
                </Modal.Footer>
            </Modal>
}

export default GenericModal;