import { useParams, useHistory } from "react-router-dom";
import { Badge, Button, Col, Icon, IconButton, Pagination, Row, SelectPicker, TagPicker } from "rsuite";
import { Link } from "react-router-dom";
import { useState, useCallback, useEffect } from "react";
import GenericDrawer from "../../components/GenericDrawer.component";
import LoadingTable from "../../components/LoadingTable.component";
import Card from "../../components/Card.component";
import MembersAreaService from "../../services/MembersArea.service";

const SchoolPage = (props) => {

    const defaultDrawerState = {
        filter: {open: false, data: {
            // date_range: [
                // format(new Date(), 'yyyy-MM-dd hh:mm:ss'),
                // format(new Date(), 'yyyy-MM-dd  hh:mm:ss')
            // ]
        }},
    }

    const [drawerState, setDrawerState] = useState(defaultDrawerState)
    const [tab, setTab] = useState(0);
    const [activePage, setActivePage] = useState(1)
    const [loading, setLoading] = useState(true)

    const [schoolPaginatedList, setSchoolPaginatedList] = useState({
        count: 0,
        sum: 0,
        pages: 0,
        data:[]
    })


    const openDrawer = (drawerName, data = {}) => setDrawerState({...drawerState, [drawerName]: {open: true, data: {...drawerState[drawerName].data, ...data}}});

    const clearDrawerData = (drawerName) => setDrawerState({...drawerState, [drawerName]: {open: false, data: {}}});

    const closeDrawer = (drawerName) => setDrawerState({...drawerState, [drawerName]: {open: false, data: {...drawerState[drawerName]?.data}}});

    const handleDrawerChange = async (field, value) => {
        setDrawerState({...drawerState, filter: {...drawerState.filter, data: {...drawerState.filter.data, [field]: value}}})
    }

    const handleTabClick = useCallback((tabNumber) => {
        setActivePage(1)
        setTab(tabNumber)
    }, []);


    const getSchoolPaginatedList = async (page = 1, filters = {}) => {
        filters = {...filters}
        setActivePage(page)
        const response = await new MembersAreaService().post(filters,`list/school/${page}`)
        setSchoolPaginatedList(response)
        setLoading(false)
    }

    const loadAllLists = async () => {
        await getSchoolPaginatedList()
        setLoading(false)
    }

    useEffect(() => loadAllLists(), [])

    const history = useHistory();

    const addNewSchool = async () => {
        return history.push('/schools/add')
    }

    const navigateToPage = (number) => getSchoolPaginatedList(number, {})


    return <>
    <Row>
        <Col md={12}>
            <h3>{props.isAdmin ? 'Administrar' : ''} Escolas</h3>
        </Col>
        <Col md={12} style={{display: 'flex', justifyContent: 'end'}}> 
            <Button appearance="primary" onClick={() => openDrawer('filter')}><Icon icon="sliders" /> Filtros</Button> 
            <Button appearance="primary" onClick={addNewSchool} style={{marginLeft: '15px'}}><Icon icon="plus" /> Nova Escola</Button> 
        </Col> 
    </Row>



        <div className="table-container">
            <div className="table">
                <div style={{display: 'flex', flexDirection: 'row'}} className="list-responsive">
                    <div className="rs-col-md-8 rs-col-xs-8">NOME</div>
                    <div className="rs-col-md-11 rs-col-xs-8">URL</div>
                    <div className="rs-col-md-3 rs-col-xs-3">STATUS</div>
                    <div className="rs-col-md-2 rs-col-xs-1"></div>
                </div>

                {
                    !schoolPaginatedList?.data?.length && loading ? <LoadingTable /> : null
                }

                {
                    !schoolPaginatedList?.data?.length && !loading ? <>
                        <Row>
                            <Col lg={24}>
                                <Card>
                                    <h5 style={{textAlign: 'center', color: 'rgba("0,0,0,0.4")'}}>Nenhuma escola cadastrada</h5>
                                </Card>
                            </Col>
                        </Row>
                    </> : null
                }   

                {
                    schoolPaginatedList?.data?.map((school, index) => {
                        return <div key={index} className="card-table-row list-responsive">
                                
                                <div className="rs-col-md-8 rs-col-xs-8">{school.name}</div>
                                <div className="rs-col-md-11 rs-col-xs-8">
                                    <small><strong>produção:</strong> https://{school?.prodDomain}<br /><strong>desenvolvimento:</strong> https://{school?.devDomain}</small>
                                </div>
                                <div className="rs-col-md-3 rs-col-xs-3">{
                                    school.active ? <Button className="payment-status-approved"> <span>Ativo</span></Button> : <Button className="payment-status-denied"> <span>Inativo</span></Button>
                                } </div>
                                <div className="rs-col-md-2 rs-col-xs-1" style={{display: 'flex', justifyContent: 'flex-end'}}> 
                                    <Link to={ props.isAdmin ? `/admin/schools/edit/${school._id}` : `/schools/edit/${school._id}`}><IconButton appearance="primary" icon={<Icon icon="edit"/>}/></Link>
                                </div>
                        </div>
                    })
                }
            </div>
        </div>


        <div style={{marginTop: '25px', display: 'flex', justifyContent: 'flex-end'}}>
            <Pagination
                size="lg"
                prev={true}
                next={true}
                first={true}
                last={true}
                ellipsis={true}
                boundaryLinks={true}
                pages={schoolPaginatedList.pages}
                maxButtons={5}
                activePage={activePage}
                onSelect={navigateToPage}
            />
        </div>

        <GenericDrawer
            // handleApply={() => {getProductsPaginatedList(1, drawerState.filter.data); closeDrawer('filter')}}
            modalState={drawerState.filter.open}
            closeModal={() => closeDrawer('filter')}
            // loading={drawerLoading}
            title="Filtros"
        >
            <Row>
                <Col lg={24} style={{textAlign: 'right'}}>
                    <Button appearance="link" onClick={() => {
                        // clearDrawerData('filter')
                        // getProductsPaginatedList(1, {});
                        }}>Limpar filtros <Icon icon="close" /></Button>
                </Col>
            </Row>
            
            <Row style={{marginTop: '15px'}}>
                <Col lg={24}>
                    <p>Produto</p>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <SelectPicker
                            style={{width: '100%'}}
                            // data={productList.map(product => {
                            //     return {
                            //         label: product.name,
                            //         value: product.id
                            //     }
                            // })}
                            searchable={true}
                            // onChange={(value, event) => { handleDrawerChange("products", value) }}
                            value={drawerState.filter.data.products}
                            cleanable={false}
                            preventOverflow={true}
                            appearance="default"
                            placeholder="Selecione o tipo do campo"
                        />
                    </div>
                </Col>
            </Row>
            <Row style={{marginTop: '15px'}}>
                <Col lg={24}>
                    <p>Categoria</p>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <TagPicker 
                            // onChange={(value) => { handleDrawerChange("category", value) }}
                            value={drawerState.filter.data.category}
                            preventOverflow={true}
                            labelKey="categoryName"
                            valueKey="_id"
                            // data={listData.productCategories} 
                        style={{ width: '100%' }}  />
                    </div>
                </Col>
            </Row>
            <Row style={{marginTop: '15px'}}>
                <Col lg={24}>
                    <p>Tipo de Produto</p>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <TagPicker 
                            // onChange={(value) => { handleDrawerChange("paymentType", value) }}
                            value={drawerState.filter.data.paymentType}
                            preventOverflow={true}
                            data={[
                                {
                                    label: "Venda Direta",
                                    value: 'single',
                                },
                                {
                                    label: "Assinatura",
                                    value: 'subscription',
                                },
                            ]} 
                        style={{ width: '100%' }}  />
                    </div>
                </Col>
            </Row>
            <Row style={{marginTop: '15px'}}>
                <Col lg={24}>
                    <p>Status</p>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <TagPicker 
                            // onChange={(value) => { handleDrawerChange("status", value) }}
                            // value={drawerState?.data.events}
                            value={drawerState.filter.data.status}
                            preventOverflow={true}
                            data={[
                                {
                                    label: "Ativo",
                                    value: true,
                                },
                                {
                                    label: "Inativo",
                                    value: false,
                                },
                            ]} 
                        style={{ width: '100%' }}  />
                    </div>
                </Col>
            </Row>

            {
                props.isAdmin ? <>
                    <hr></hr>
                    <Row style={{marginTop: '15px'}}>
                        <Col lg={24}>
                            <p>Filtros de Administrador</p>
                        </Col>
                    </Row>
                    <Row style={{marginTop: '15px'}}>
                        <Col lg={24}>
                            <p>Vendedor do Produto</p>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <SelectPicker 
                                    // onChange={(value) => { handleDrawerChange("ownerId", value) }}
                                    value={drawerState.filter.data.ownerId}
                                    preventOverflow={true}
                                    // data={sellerList.map(seller => {
                                    //     return {
                                    //         label: `${seller.name} - ${seller.email}`,
                                    //         value: seller._id
                                    //     }
                                    // })} 
                                style={{ width: '100%' }}  />
                            </div>
                        </Col>
                    </Row>
                </> : null
            }
            
        </GenericDrawer>
</>
    
}
export default SchoolPage;