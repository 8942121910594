/* eslint-disable jsx-a11y/alt-text */
import React, { useCallback, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';

import ReactCrop from 'react-image-crop'
import { Button, Col, Icon, IconButton, Loader, Modal, Row } from "rsuite";

import 'react-image-crop/dist/ReactCrop.css'
import UploadService from '../services/Upload.service';

const ImageCropUploader = (props) => {

    const [loading, setLoading] = useState(false)
    
    const defaultImage = {
        isCropping: false,
        blob: null,
        uploaded: null
    }

    // console.log(useDropzone)

    const [image, setImage] = useState(defaultImage)

    const [crop, setCrop] = useState({
        unit: "%",
        x: 0,
        y: 0,
        width: 60,
        // height: 45,
    })

    const onDrop = (acceptedFiles) => {
        setImage({
            isCropping: true,
            blob: URL.createObjectURL(acceptedFiles[0]),
            file: acceptedFiles[0]
        })
    };
        
    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: 'image/*',
        multiple: false
    });
    

    useEffect(()=>{
        if(props?.src){
            setImage(URL.createObjectURL(props?.src?.blobFile))
        }
        setCrop({
            ...crop,
            width: props.width,
            height: props.height,
            aspect: props.width / props.height,
            y: props.y || 0,
            x: props.x || 0
        })

        if(props?.state && props?.state?.url){
            setImage({
                ...image,
                uploaded: props.state
            })
        }
    }, [props])

    const handleCropConfirm = () => {
        const formData = new FormData();
        
        formData.append('cropData', JSON.stringify(crop));
        formData.append('file', image.file);
        
        const xhr = new XMLHttpRequest();
        xhr.open('POST', new UploadService().getUploadEndpoint()); // Substitua 'URL_DO_BACKEND' pela URL real
        xhr.setRequestHeader('Authorization', `Bearer ${new UploadService().getToken()}`); // Substitua 'SEU_TOKEN' pelo token real

        xhr.onload = () => {
            setLoading(false)
            if (xhr.status === 200) {
                if(props.onUploadComplete){
                    const response = JSON.parse(xhr.responseText)
                    if(response?.url){
                        setImage({...image, isCropping: false, uploaded: response})
                    }
                    props.onUploadComplete(response)
                }
            } else {
                if(props.onUploadFail){
                    props.onUploadFail(xhr.response)
                }
                console.error('Erro ao enviar o arquivo:', xhr.statusText);
            }
        };

        xhr.send(formData);

        setLoading(true)

        setImage({
            ...image,
            isCropping: false,
        })
        if(props.onUploadStart){
            props.onUploadStart()
        }
    }

    return <>
        <div>
            {
                !image.uploaded ? <div {...getRootProps()} style={dropzoneStyles}>
                    <input {...getInputProps()} />
                    <p><Icon icon="file-upload" size="2x"></Icon></p>
                    <p>Arraste arquivos aqui <br />ou clique para selecionar</p>
                    <hr />
                    <small>JPG ou PNG até 1mb</small>
                </div> : <>
                    {
                        !loading ? <div style={{width: '100%', height: '100%'}}>
                            <div style={{position: 'absolute', zIndex: 999, width: '100%', height: '100%'}}>
                            <Row style={{
                                        height: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}>
                                    <Col lg={24} style={{display: 'flex', justifyContent: 'center'}}>
                                        <div className="thumb-button" onClick={()=>{setImage(defaultImage); props.onRemoveFile()}}><Icon style={{color: 'white'}} size='lg' icon="trash" /></div>
                                    </Col>
                                </Row>
                            </div>
                            <div style={{filter: 'brightness(0.5)', width:'100%', height: '180px', display: 'flex', backgroundColor: '#ccc', justifyContent: 'center', alignItems: 'center'}}>
                                <img width={props.preview_width || null} height={props.preview_height || null} src={image?.uploaded?.url} />
                            </div>
                        </div> : <div style={{width:'100%', height: '180px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                            <Row>
                                <Col lg={24}>
                                    <Loader size="md" />
                                </Col>
                            </Row>
                            <Row style={{marginTop: '15px'}}>
                                <Col lg={24}>
                                    <p>Enviando, aguarde...</p>
                                </Col>
                            </Row>
                        </div> 
                    }
                </>
            }
        </div>
    <Modal overflow={false} backdrop={true} size={'sm'} show={image.isCropping}>
        <Modal.Header closeButton={false}>
            <Modal.Title>Recortar imagem</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{display: 'flex', justifyContent: 'center'}}>
            <ReactCrop unit="%" ruleOfThirds={true} crop={crop}  aspect={props.aspect || null} onChange={(_, pecentCrop) => setCrop(pecentCrop)} >
            {    
                image.blob ? <img src={image.blob} width={'100%'} height={'100%'} /> : null
            }
            </ReactCrop>
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={()=>handleCropConfirm()} appearance="primary">
                <Icon icon="crop" /> Aplicar
            </Button>
        </Modal.Footer>
    </Modal>
    </>
    
};

const dropzoneStyles = {
    border: '2px dashed #cccccc',
    borderRadius: '4px',
    textAlign: 'center',
    padding: '20px',
    cursor: 'pointer'
};

export default ImageCropUploader;