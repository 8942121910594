import BaseService from './base/base.service'
import AuthService from './Authentication.service';

export default class UploadService extends BaseService {

    constructor(){
        super('upload')
    }

    getUploadEndpoint(){
        return this.BASE_URL
    }

    async getUploadSignedEndpoint(fileName, mimeType){
        try {
            const payload = {
                fileName,
                mimeType
            }
            const response = await fetch(`${this.BASE_URL}/audio/signed_url`, {
                "method": "POST",
                "headers": {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${this.getToken()}`,
                    'Cookie': document.cookie
                },
                "body": JSON.stringify(payload)
            })

            const json = await response.json()

            if(json && json.error) {
                throw json.error
            }

            return json
        } catch (error) {
            return false
        }
    }
}