import { Button, Drawer } from "rsuite"

const GenericDrawer = (props) => {
    return  <Drawer
                show={props.modalState}
                onHide={props.closeModal}
                size={props.size ? props.size : 'xs'}
                placement={props.placement ? props.placement : 'right'}
            >
                <Drawer.Header>
                <Drawer.Title>{props.title} {props.titleAction ? props.titleAction : null }</Drawer.Title>
                </Drawer.Header>
                <Drawer.Body>
                    {props.children}
                </Drawer.Body>
                <Drawer.Footer style={{display: 'flex', justifyContent: 'right', width: '87%', position: 'fixed', bottom: 0}}>
                    { props.handleApply ? <Button loading={props.loading || false} onClick={props.handleApply || props.closeModal} appearance="primary">{props.applyLabel || 'Aplicar'}</Button> : null }
                    { !props.hideCancelButton ? <Button onClick={props.closeModal} appearance="subtle">{props.closeLabel || 'Cancelar'}</Button> : null }
                </Drawer.Footer>
            </Drawer>
}

export default GenericDrawer;