import React, { useState, useRef, useEffect } from 'react';
import { Col, Loader, Row, Progress, Tag, Icon } from 'rsuite';
import * as tus from 'tus-js-client'
import MembersAreaService from "../../../services/MembersArea.service"
import './DragDropUpload.css';


const { Line } = Progress;

const VideoUpload = (props) => {

  const { title, lessonId } = props

  const [isDragging, setIsDragging] = useState(false);
  const [file, setFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [videoPreview, setVideoPreview] = useState(null);
  const [uploadStatus, setUploadStatus] = useState(null);
  const [uploadInstance, setUploadInstance] = useState(null);
  const [uploadMessage, setUploadMessage] = useState({color: '#0c0c0c', body: 'Preparando o terreno...'});
  const fileInputRef = useRef(null);
  const [uploadMetadata, setUploadMetadata] = useState({
    signature: null,
    expires: 0,
    video_library_id: null
  });

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    if(uploadStatus !== "active" && uploadStatus !== "success"){
      e.preventDefault();
      setIsDragging(false);
  
      const droppedFile = e.dataTransfer.files[0];
      setFile(droppedFile);
      if(droppedFile) setVideoPreview(URL.createObjectURL(droppedFile)); // Create preview URL
      uploadFile(droppedFile);
    }
  };

  const handleUploadClick = () => {
    if(uploadStatus !== "active" && uploadStatus !== "success"){
        fileInputRef.current.click();
    }
  };

  const handleFileSelect = (e) => {
    if(uploadStatus !== "active" && uploadStatus !== "success"){
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
        if(selectedFile) setVideoPreview(URL.createObjectURL(selectedFile)); // Create preview URL
        uploadFile(selectedFile);
        simulateUpload(selectedFile);
    }
  };

  const simulateUpload = (file) => {
    // ...

    // const simulateUpload = () => {
    //     const totalChunks = 10; // Número total de chunks para upload
    //     let uploadedChunks = 0; // Contador de chunks já enviados
    //     const progressInterval = setInterval(() => {
    //       // Atualiza o progresso a cada intervalo de tempo
    //       setUploadProgress((prevProgress) => {
    //         const newProgress = prevProgress + 10; // Incrementa o progresso em 10% por chunk
    //         return newProgress > 100 ? 100 : newProgress; // Limita o progresso em 100%
    //       });
    //       uploadedChunks++; // Incrementa o contador de chunks enviados
    //     }, 500); // Atualiza o progresso a cada 500ms
    // };

    // simulateUpload();
  };

  const createBunnyVideo = async (name) => {
    const memberAreaService = new MembersAreaService()
    const response = await memberAreaService.post({name},'lesson/video')
    if(lessonId){
      await memberAreaService.put({_id: lessonId, videoSource: {video_id: response.videoId, status: "processing", duration: document.querySelector('video')?.duration || 0} }, "update/lesson")
    }
    if(props.uploadCallbackFn){
      props.uploadCallbackFn(response)
    }
    return response
  }

  const getVideoDuration = () => {
    if(videoPreview){
        const timeInSeconds = document.querySelector('video')?.duration || 0
    
        const hours = Math.floor(timeInSeconds / 3600);
        const minutes = Math.floor((timeInSeconds % 3600) / 60);
        const seconds = Math.floor(timeInSeconds % 60);
      
        const formattedHours = String(hours).padStart(2, '0');
        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(seconds).padStart(2, '0');
      
        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    }

    return ""
  };

  const loadUploadMetadata = async (video_id) => {
    const response = await new MembersAreaService().get(`upload/signature/${video_id}`)
    return response
  }


  const uploadFile = async (selectedFile) => {
    setUploadStatus('active')
    if (selectedFile) {
      setUploadMessage({color: '#0c0c0c', body: `Em suas marcas...`})
      const bunny_video = await createBunnyVideo(title)
      setUploadMessage({color: '#0c0c0c', body: `Em suas marcas... preparar...`})
      const uploadMetadata = await loadUploadMetadata(bunny_video.videoId)
      
      setUploadMessage({color: '#0c0c0c', body: `Em suas marcas... preparar... apontar...`})
      if(bunny_video.videoId){
        const upload = new tus.Upload(selectedFile, {
          endpoint: 'https://video.bunnycdn.com/tusupload',
          retryDelays: [0, 3000, 5000, 10000, 20000, 60000, 60000],
          headers: {
            AuthorizationSignature: uploadMetadata.signature,
            AuthorizationExpire: uploadMetadata.expires,
            VideoId: bunny_video.videoId,
            LibraryId: uploadMetadata.video_library_id,
          },
          metadata: {
            filetype: selectedFile.type,
            title,
            collection: bunny_video.collectionId,
          },
          onError: function (error) {
              setUploadStatus('fail')
              setUploadMessage({color: 'red', body: 'Erro no envio, verifique sua conexão e tente novamente.'})
              console.log(error)
              document.querySelector('video').pause()
  
          },
          onProgress: function (bytesUploaded, bytesTotal) {
              console.log(`${bytesUploaded}/${bytesTotal}`)
              setUploadStatus('active')
              const progress = (bytesUploaded / bytesTotal) * 100
              setUploadMessage({color: '#3498ff', body: `${Math.ceil(progress)}% Enviado...`})
              setUploadProgress(progress)
          },
          onSuccess: function () {
              setUploadMessage({color: 'green', body: `Video enviado com sucesso! 🚀`})
              setUploadStatus('success')
          },
        });

        setUploadInstance(upload)

        upload.findPreviousUploads().then(function (previousUploads) {
          if (previousUploads.length) {
            upload.resumeFromPreviousUpload(previousUploads[0]);
          }
          upload.start();
  
        });
        
      }
    }
  };

  const cancelUpload = () => {
    if(uploadInstance != null){
      uploadInstance.abort(true).then(function () {
        setFile(null)
      }).catch(function (err) {
        setFile(null)
      })
    }
  }

  return (
    <div
      className={`drag-drop-upload ${isDragging ? 'dragging' : ''} ${file ? 'fileSelected' : 'draggable'}`}
      onDragEnter={handleDragEnter}
      onDragOver={(e) => e.preventDefault()}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      onClick={handleUploadClick}
    >
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileSelect}
      />

      {file ? (
        <>
            <div style={{display: 'flex'}}>
                <div style={{display: 'flex', justifyContent: 'left'}}>
                    {videoPreview && <video src={videoPreview} style={uploadStatus === "fail" ? {filter: 'grayscale(2)'} : {}} autoPlay={true} muted={true} loop={true} width={'190px'} />}
                </div>
                <div style={{marginLeft: '15px'}}>
                    <Row>
                        <Col lg={20} style={{display: 'flex',flexDirection: 'column', alignItems: 'flex-start'}}>
                            
                          {
                              uploadStatus === "active" ?  <Loader /> : null
                          }

                          {
                              uploadStatus === "fail" ?  <Icon icon={'exclamation-circle'} style={{color: 'red'}} size="lg" /> : null
                          }
                          {
                              uploadStatus === "success" ?  <Icon icon={'check'} style={{color: 'green'}} size="lg" /> : null
                          }
                        
                        <span> {file?.name}</span></Col>
                        <Col lg={4} style={{display: 'flex'}}><span style={{fontWeight: 'bold'}}> {getVideoDuration()}</span></Col>
                    </Row>
                    <Row>
                        <Col lg={24} style={{display: 'flex', justifyContent: 'left', marginTop: '15px'}}><small style={{color: uploadMessage.color}}>{uploadMessage.body}</small></Col>
                    </Row>
                    {
                        uploadStatus === "active" ? <Row>
                            <Col lg={24} style={{display: 'flex', justifyContent: 'left', marginTop: '15px'}}><Tag onClick={cancelUpload} style={{backgroundColor: "#ffddd6", color: "#FF5630"}}><Icon icon={'ban'} style={{marginRight: '5px'}}/> Cancelar</Tag></Col>
                        </Row> : null
                    }
                    
                </div>
            </div>
            <Row>
                <Col lg={24} style={{marginTop: '10px'}}>
                    <Line percent={uploadProgress} showInfo={false} status={uploadStatus} />
                </Col>
            </Row>
        </>
      ) : (
        <Row>
            <Col lg={24}>
                <div class="icons fa-4x">
                  <i className="fas fa-file-video" style={{ transform: 'scale(0.333) translateY(2px) translateX(-6px) rotate(-45deg)' }}></i>
                  <i className="fas fa-file-video" style={{ transform: 'scale(0.5) translateY(-4px)' }}></i>
                  <i className="fas fa-file-video" style={{ transform: 'scale(0.333) translateY(2px) translateX(6px) rotate(45deg)' }}></i>
                </div>
            </Col>
            <Col lg={24}>
                <p style={{fontSize: '1.1rem'}}>Arraste e solte o vídeo dentro desta área ou clique para selecionar um arquivo..</p>
            </Col>
          {/* <p className="drag-drop-upload__instructions">(O formato suportado é .mp4)</p> */}
        </Row>
      )}
    </div>
  );
};

export default VideoUpload;
