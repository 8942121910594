import React, { useCallback, useReducer, useState, useEffect } from 'react';
import { Button, Col, FlexboxGrid, Icon, Input, InputGroup, Loader, Message, Modal, Notification, Row, SelectPicker, Toggle } from "rsuite"
import { useParams, useHistory, Link } from "react-router-dom";
import Card from '../../components/Card.component';
import FieldValidator from '../../components/FieldValidator.component';
import ImageCropUploader from '../../components/ImageCropper.component';
import MembersAreaService from '../../services/MembersArea.service';

import FormCodeEditor from '../../components/CodeEditor.component';
import GenericModal from '../../components/GenericModal.component';
import VideoUpload from './components/VideoUpload.component';

const LogoPreview = (props) => {
    let desktopStyle = { width: '100%', height: '40%', backgroundColor: '#6b7280', backgroundPosition: 'center', backgroundSize: 'cover' }

    return <>
    {props.device === "desktop" ? <div style={{display: 'flex', flexDirection: 'column',width:'100%', height: '180px', }}>
        <div style={{width: '100%', height: '100%', backgroundColor: '#000000', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            <img src={props.logoImage} width="30px" />
            <div style={{backgroundColor: '#d2d6dc', height: '50%', width: '150px', borderRadius: '5px',  display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <Input style={{width: '80%', height: '10px', fontSize: '0.7em'}}></Input>
                <Input style={{width: '80%', height: '10px', marginTop: '5px', fontSize: '0.7em'}} type="password"></Input>
                <Button appearance='primary' color="red" size="xs" style={{marginTop: '5px', fontSize: '0.6em'}}>Entrar</Button>
            </div>
        </div>  
    </div> : null}
</>;
}

const AddSchoolPage = (props) => {

    let { id } = useParams()
    const history = useHistory()

    const [formState, setFormState] = useState({
        name: "",
        logoImage: {},
        carroussel: [
            {
                id: 1,
                order: 1,
                data: {}
            },
            {
                id: 2,
                order: 2,
                data: {}
            },
            {
                id: 3,
                order: 3,
                data: {}
            },
        ],
        carrousselMobile: [
            {
                id: 1,
                order: 1,
                data: {}
            },
            {
                id: 2,
                order: 2,
                data: {}
            },
            {
                id: 3,
                order: 3,
                data: {}
            },
        ],
        shortVideos: [],
        enableComments: false,
        enableForum: false,
    });

    const [validator, setValidator] = useState({
        name: {},
    });

    const [tab, setTab] = useState(1);

    const [courseCardState, setCourseCardState] = useState(1);
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [courseList, setCourseList] = useState([]);

    const defualtShortVideoModalState = {
        open: false,
        loading: false,
        data: {
            name: `Video curto ${Date.now()}`,
            videoSource:{
                status: "not_uploaded"
            }
        }
    }
    const [shortVideoModalState, setShortVideoModalState] = useState(defualtShortVideoModalState);

    const getCourseList = async () => {
        const response = await new MembersAreaService().get('course/list')
        setCourseList(response)
    }

    const handleTabClick = useCallback((tabNumber) => {
        setTab(tabNumber)
    }, []);

    const getTabItemsMobile = () => {
        let tabItems = [
            {label: 'Cursos', value: 2},
            {label: 'Vídeos Curtos', value: 5},
            // {label: 'Professores', value: 6},
            {label: 'Configurações', value: 1},
            {label: 'Estilos', value: 3},
            {label: 'Domínios', value: 4},
        ]
        return tabItems
    }
    
    const formValidations = (field, value, required = true, custom = false) => {
        let payload = {}

        if(required && !value){
            payload = {...payload, [field]: {hasError: true, message: "Você deve preencher este campo"}}
        } else {
            payload = {...payload, [field]: {hasError: false, message: null}}
        }

        const fields_ignored_list = ['enableComments', 'enableForum']

        if(fields_ignored_list.includes(field)){
            payload = {...payload, [field]: {hasError: false, message: null}}
        }
        return payload
    }

    const handleFormChange = async (field, value, required = false) => {
        setFormState({...formState, [field]: value})
        if(required){
            setValidator({...validator, ...formValidations(field, value, required)})
        }
    }

    const addCourseToSchool = (payload) => {
        console.log(formState?.courses)
        console.log(payload)
        console.log(formState?.courses?.find(course => course._id === payload._id))
        const already_added = formState?.courses?.find(course => course._id === payload._id)

        if(!already_added){
            setFormState({...formState, courses: [...formState.courses, payload]})
        }else{
            Notification['error']({
                title: "Erro",
                description: <p>O curso já foi adicionado nesta escola.</p>
            });
        }

        setSelectedCourse(null)
    }

    const removeCourse = (course_id) => {
        const all_courses = formState.courses.filter(course => course._id !== course_id) || []
        setFormState({...formState, courses: all_courses})
    }

    const addShortVideoToSchool = (short_video) => {
        if(!short_video?.videoSource?.videoId){
            Notification['error']({
                title: "Erro",
                description: <p>Faça o upload do vídeo antes de salvar.</p>
            });

            return 
        }
        const already_added = formState?.shortVideos?.find(shortVideo => (shortVideo === short_video?._id) || (shortVideo?._id === short_video?._id))

        if(!already_added){
            // console.log('formState.shortVideos: ',formState?.shortVideos)
            setFormState({...formState, shortVideos: [...formState?.shortVideos || [], short_video]})
        }

        setShortVideoModalState(defualtShortVideoModalState)
    }

    const removeShortVideoToSchool = (short_video) => {
        // console.log('short_video, ', short_video)
        // console.log('formState.shortVideos, ', formState.shortVideos)
        const all_courses = formState.shortVideos.filter(shortVideo => shortVideo?._id !== short_video?._id) || []
        // console.log('all_courses', all_courses)
        setFormState({...formState, shortVideos: all_courses})
    }

    const setSliderData = (id, value) => {
        setFormState({...formState, carroussel: formState.carroussel.map(item => {
            if(item.id === id){
                item.data = value
            }

            return item
        })})
    }
    const setSliderMobileData = (id, value) => {
        console.log(id, value)
        setFormState({...formState, carrousselMobile: formState.carrousselMobile.map(item => {
            if(item.id === id){
                item.data = value
            }

            return item
        })})
    }

    const formPostValidation = (formData) => {
        const fields = Object.keys(validator)
        let payload = {}

        fields.map(field => {
            const value = formData[field]
            payload = {...payload, ...formValidations(field, value, false, formData)}
        })

        console.log(payload)
        setValidator({...validator, ...payload})
        return !Object.values(payload).map(item => item.hasError).includes(true)
    }

    const [buttonLoading, setButtonLoading] = useState(false);

    const normalizeFormData = (payload) => {
        let modifiedData = {
            ...payload
        }

        modifiedData.courses = modifiedData?.courses?.map((course)=>{
            return course._id
        })

        modifiedData.shortVideos = modifiedData?.shortVideos?.map((shortVideo)=>{
            return shortVideo._id
        })

        return modifiedData
    }

    const createShortVideo = async (videoSource) => {
        const response = await new MembersAreaService().post({...shortVideoModalState?.data, videoSource}, 'shortvideo')
        setShortVideoModalState({...shortVideoModalState, data: {...shortVideoModalState.data, ...response}})
    }

    const handleSaveClick = async () => {
        setButtonLoading(true)

        const validated = formPostValidation(formState)

        if(!validated){
            setButtonLoading(false)
            return
        }

        if(!id){
            const response = await new MembersAreaService().post(normalizeFormData(formState), 'school')
            if(response._id){
                Notification['success']({
                    title: "Sucesso",
                    description: <p>A escola {formState?.name} foi salva.</p>
                });
                history.push('/schools')
            }
        }else{
            const response = await new MembersAreaService().put(normalizeFormData(formState), 'update/school/')
            if(response){
                Notification['success']({
                    title: "Sucesso",
                    description: <p>A escola {formState?.name} foi salva.</p>
                });
                history.push('/schools')
            }
        }

        setButtonLoading(false)
    }

    const loadSchool = async (id) => {
        const response = await new MembersAreaService().get(`school/${id}`)
        
        setFormState(response)
    }
    const getDefaulTheme = async () => {
        const response = await new MembersAreaService().get(`theme/default`)
        setFormState({...formState, decodedThemeStyle: response.decodedThemeStyle})
    }

    useEffect(()=>{
        if(id){
            loadSchool(id)
        }else{
            getDefaulTheme()
        }

        getCourseList()
    },[])

    const onCodeChange = (value) => {
        setFormState({...formState, decodedThemeStyle: value})
    }

    const toggleOption = (key) => {
        setFormState({...formState, [key]: !formState[key]})
    }

    const getVideoPreviewUrl = (video_id) => {
        return `'${process.env.REACT_APP_API_URL}/members/short_video/thumbnail/preview/${video_id}'`
    }


    return <>
    {
        // console.log(formState)
    }
        <Row>
                <Col lg={12} style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <Link to={ props.isAdmin ? '/admin/schools' : '/schools'}><i className="fas fa-arrow-left" style={{ fontSize: '2em', color: '#575757' }}></i></Link>
                    <h3 style={{marginLeft: '15px'}}>{ id ? 'Gerenciar': 'Criar'} Escola</h3>
                </Col>
            </Row>

            <Row className="only-desk">
                <div style={{margin: '20px 5px', padding: '15px 5px', background: 'rgba(255, 255, 255, 0.6)', display: 'flex', flexDirection: 'row'}}>
                    <div style={{width: '100%'}}>
                        {/* { id ? <Button appearance={tab === 0 ? 'primary' : 'subtle'} onClick={() => handleTabClick(0)}>Painel</Button> : null} */}
                        <Button appearance={tab === 2 ? 'primary' : 'subtle'} onClick={() => handleTabClick(2)}>Cursos</Button>
                        <Button appearance={tab === 5 ? 'primary' : 'subtle'} onClick={() => handleTabClick(5)}>Vídeos Curtos</Button>
                        {/* <Button appearance={tab === 6 ? 'primary' : 'subtle'} onClick={() => handleTabClick(6)}>Professores</Button> */}
                        <Button appearance={tab === 1 ? 'primary' : 'subtle'} onClick={() => handleTabClick(1)}>Configurações</Button>
                        <Button appearance={tab === 3 ? 'primary' : 'subtle'} onClick={() => handleTabClick(3)}>Estilos</Button>
                        <Button appearance={tab === 4 ? 'primary' : 'subtle'} onClick={() => handleTabClick(4)}>Domínios</Button>
                        {/* <Button appearance={tab === 5 ? 'primary' : 'subtle'} onClick={() => handleTabClick(5)}>Certificado</Button> */}
                    </div>
                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Button appearance='link'><a href={`https://${formState?.devDomain}`} target="_blank"><Icon icon="external-link" style={{marginRight: '5px'}}  />Pré-visualizar Escola</a></Button>
                    </div>
                </div>
            </Row>

            <Row className="only-mobile" style={{marginTop: '25px'}}>
                <Col xs={24} sm={24}>
                    <SelectPicker
                        searchable={false}
                        cleanable={false}
                        value={tab}
                        onChange={(value) => handleTabClick(value)}
                        data={getTabItemsMobile()} 
                    style={{ width: '100%' }} />
                </Col>
            </Row>

            {
                tab === 1 ? <>
                    <Row>
                        <Col lg="8" xs="24">
                            <h5 style={{paddingBottom: '10px', marginTop: '25px'}}>Identidade Visual</h5>
                            <p>Configure a identidade visual da escola, da forma que será apresentada para os alunos.</p>
                        </Col>
                        <Col lg="16" xs="24">
                            <Card>
                                <Row>
                                    <Col lg={14}>
                                    <p style={{marginBottom: '6px', paddingLeft: '3px'}}>Nome da Escola *</p>
                                    <InputGroup>
                                        
                                        <Input value={formState.name} onChange={(event) => {handleFormChange("name", event, true)}} />
                                    </InputGroup>
                                    <FieldValidator field="name" validator={validator} />
                                    </Col>
                                </Row>

                                <Row style={{marginTop: '25px'}}>
                                    <Col lg={14}>
                                    <p style={{marginBottom: '6px', paddingLeft: '3px'}}>Página de venda <small>(para alunos existentes comprarem novos cursos) </small></p>
                                    <InputGroup>
                                        <InputGroup.Addon> <Icon icon="link"/></InputGroup.Addon>
                                        <Input value={formState.sellPage} onChange={(event) => {handleFormChange("sellPage", event, false)}} />
                                    </InputGroup>
                                    <FieldValidator field="sellPage" validator={validator} />
                                    </Col>
                                </Row>


                                <Row>
                                    <Col lg={14}><p style={{marginTop: '25px', marginBottom: '6px'}}>Logotipo da Escola</p></Col>
                                    <Col lg={10}><p style={{marginTop: '25px', marginBottom: '6px'}}>Prévia</p></Col>
                                </Row>
                                <Row>
                                    <Col lg={14}>
                                            <ImageCropUploader
                                                state={formState.logoImage}
                                                width="100" //percent %
                                                height="100" //percent %
                                                // aspect={10/16}
                                                // x={55 / 2}
                                                preview_width="60%"
                                                onUploadComplete={(response)=>{
                                                    handleFormChange("logoImage", response)
                                                }}
                                                onRemoveFile={()=>{
                                                    handleFormChange("logoImage", {})
                                                }}
                                            />
                                    </Col>
                                    <Col lg={10}>
                                        <LogoPreview logoImage={formState?.logoImage?.url} device="desktop" />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col lg={12}><p style={{marginTop: '25px', marginBottom: '6px'}}>Carrossel (Desktop)</p></Col>
                                </Row>
                                <Row>
                                    <Col lg={8}>
                                        <ImageCropUploader
                                            state={formState?.carroussel?.find(item => item.id === 1)?.data}
                                            width="100" //percent %
                                            height="45" //percent %
                                            preview_width="100%"
                                            y={45 / 2}
                                            aspect={16/5}
                                            onUploadComplete={(response)=>{
                                                setSliderData(1, response)
                                            }}
                                            onRemoveFile={()=>{
                                                setSliderData(1, {})
                                            }}
                                        />
                                    </Col>
                                    <Col lg={8}>
                                        <ImageCropUploader
                                            state={formState?.carroussel?.find(item => item.id === 2)?.data}
                                            width="100" //percent %
                                            height="45" //percent %
                                            preview_width="100%"
                                            y={45 / 2}
                                            aspect={16/5}
                                            onUploadComplete={(response)=>{
                                                setSliderData(2, response)
                                            }}
                                            onRemoveFile={()=>{
                                                setSliderData(2, {})
                                            }}
                                        />
                                    </Col>
                                    <Col lg={8}>
                                        <ImageCropUploader
                                            state={formState?.carroussel?.find(item => item.id === 3)?.data}
                                            width="100" //percent %
                                            height="45" //percent %
                                            preview_width="100%"
                                            y={45 / 2}
                                            aspect={16/5}
                                            onUploadComplete={(response)=>{
                                                setSliderData(3, response)
                                            }}
                                            onRemoveFile={()=>{
                                                setSliderData(3, {})
                                            }}
                                        />
                                    </Col>
                                </Row>

                                <hr></hr>

                                <Row>
                                    <Col lg={12}><p style={{marginTop: '25px', marginBottom: '6px'}}>Carrossel (Mobile)</p></Col>
                                </Row>
                                <Row>
                                    <Col lg={8}>
                                        <ImageCropUploader
                                            state={formState?.carrousselMobile?.find(item => item.id === 1)?.data}
                                            width="75" //percent %
                                            height="65" //percent %
                                            y={65 / 2}
                                            aspect={16/10}
                                            preview_width="100%"
                                            onUploadComplete={(response)=>{
                                                setSliderMobileData(1, response)
                                            }}
                                            onRemoveFile={()=>{
                                                setSliderMobileData(1, {})
                                            }}
                                        />
                                    </Col>
                                    <Col lg={8}>
                                        <ImageCropUploader
                                            state={formState?.carrousselMobile?.find(item => item.id === 2)?.data}
                                            width="75" //percent %
                                            height="65" //percent %
                                            y={65 / 2}
                                            aspect={16/10}
                                            preview_width="100%"
                                            onUploadComplete={(response)=>{
                                                setSliderMobileData(2, response)
                                            }}
                                            onRemoveFile={()=>{
                                                setSliderMobileData(2, {})
                                            }}
                                        />
                                    </Col>
                                    <Col lg={8}>
                                        <ImageCropUploader
                                            state={formState?.carrousselMobile?.find(item => item.id === 3)?.data}
                                            width="75" //percent %
                                            height="65" //percent %
                                            y={65 / 2}
                                            aspect={16/10}
                                            preview_width="100%"
                                            onUploadComplete={(response)=>{
                                                setSliderMobileData(3, response)
                                            }}
                                            onRemoveFile={()=>{
                                                setSliderMobileData(3, {})
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row> 
                    <Row>
                        <Col lg="8" xs="24">
                            <h5 style={{paddingBottom: '10px', marginTop: '25px'}}>Interatividade</h5>
                            <p>Configure como os alunos interagem com a sua escola.</p>
                        </Col>
                        <Col lg="16" xs="24">
                            <Card>
                                <Row>
                                    <Col lg="24" style={{display: 'flex', alignItems: 'center'}} className="paymentMethodSpaceing">
                                        <Toggle size="md" onChange={() => {toggleOption('enableComments')}} checked={formState.enableComments}/>
                                        <p style={{ paddingLeft: '5px'}}>Habilitar comentários nos vídeos</p>
                                    </Col>
                                </Row>
                                <Row style={{marginTop: '15px'}}>
                                    <Col lg="24" style={{display: 'flex', alignItems: 'center'}} className="paymentMethodSpaceing">
                                        <Toggle size="md" onChange={() => {toggleOption('enableForum')}} checked={formState.enableForum} />
                                        <p style={{ paddingLeft: '5px'}}>Habilitar fórum</p>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row> 
                </> : null
            }
            
            {
                tab === 2 ? <>
                    <Row>
                        <FlexboxGrid justify="start">
                            <Col lg="4">
                                {
                                    courseCardState === 1 ? <div onClick={()=>setCourseCardState(2)} style={{width: '100%',  height: '40vh',border: '3px dashed #ccc', cursor: 'pointer', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}>
                                        <div><Icon style={{color: '#3498ff'}} icon="plus-circle" size='3x'></Icon></div>
                                        <div><span style={{fontWeight: 'bold', color: 'rgba(0,0,0,0.5)'}}>Adicionar curso</span></div>
                                    </div> : null
                                }
                                {
                                    courseCardState === 2 ? <div style={{width: '100%', height: '40vh', border: '3px dashed #ccc', cursor: 'pointer', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}>
                                        <div style={{width: '80%'}}>
                                            <SelectPicker
                                                data={courseList}
                                                value={selectedCourse}
                                                onChange={(value)=>{setSelectedCourse(value)}}
                                                placeholder="Selecionar curso"
                                                labelKey="name"
                                                valueKey="_id"
                                                cleanable={false}
                                                style={{width: '100%'}}
                                            />
                                        </div>
                                        <div style={{marginTop: '15px', width: '80%', display: 'flex', justifyContent: "space-evenly"}}>
                                            <Button appearance='primary' onClick={()=>{addCourseToSchool(courseList?.find(item => item._id === selectedCourse))}} size="xs">Adicionar</Button>
                                            <Button appearance='primary' color="red" size='xs'>Cancelar</Button>
                                        </div>

                                    </div> : null
                                }
                            </Col>
                            {
                                formState?.courses?.sort((a, b) => a.order - b.order)?.map(course => {
                                    return <Col lg={4}>
                                    <div style={{border: '3px dashed #3498ff', width: '100%', height: '40vh', background: `url(${course?.courseSliderCover?.url})`, backgroundSize: 'cover'}}>
                                    <Row style={{
                                        height: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}>
                                    <Col lg={24} style={{display: 'flex', justifyContent: 'center'}}>
                                        <div className="thumb-button" onClick={()=>removeCourse(course._id)}><Icon style={{color: 'white'}} size='lg' icon="trash" /></div>
                                    </Col>
                                </Row>
                                    </div>
                                    <div style={{marginTop: '0.3rem'}}>
                                        <h6>{course?.name}</h6>
                                    </div>
                                    </Col>
                                })
                            }
                        </FlexboxGrid>


                    </Row>
                    
                </> : null
            }
            {
                tab === 3 ? <>
                    <Row>
                        <Col lg="8" xs="24">
                            <h5 style={{paddingBottom: '10px', marginTop: '25px'}}>Estilos CSS</h5>
                            <p>Personalize a cara da sua escola com os estilos CSS.</p>
                        </Col>
                        <Col lg="16" xs="24">
                            <FormCodeEditor code={formState.decodedThemeStyle} onCodeChange={onCodeChange} />
                        </Col>
                    </Row>
                    
                </> : null
            }
            {
                tab === 4 ? <>
                    <Row>
                        <Col lg="8" xs="24">
                            <h5 style={{paddingBottom: '10px', marginTop: '25px'}}>URL e Domínio da Escola</h5>
                            <p>Configure como os alunos irão acessar seu curso, através de domínio personalizado ou com a URL gerada pela nossa plataforma.</p>
                        </Col>
                        <Col lg="16" xs="24">
                            <Card>
                                <Row>
                                    <Row>
                                        <Col lg={18}>
                                            <p style={{marginBottom: '6px', paddingLeft: '3px'}}>Subdomínio padrão <small>(para testes e desenvolvimento)</small></p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={18}>
                                        <InputGroup>
                                            <Input value={'https://meucurso.D4Pay.app'} disabled />
                                            <InputGroup.Addon> <Icon icon="lock" style={{color: 'green', marginRight: '10px'}} /> <small style={{color: 'green'}}>SSL Válido</small></InputGroup.Addon>
                                        </InputGroup>
                                        <FieldValidator field="name" validator={validator} />
                                        </Col>
                                        <Col lg={6} style={{display: 'flex', justifyContent: 'center', alignItems: 'flex-end'}}>  <Button appearance='link'><Icon icon="external-link" style={{marginRight: '5px'}}  />Acessar</Button> </Col>
                                    </Row>
                                </Row>
                                <hr></hr>
                                <Row style={{marginTop: '25px'}}>
                                    <Row>
                                        <Col lg={18}>
                                            <p style={{marginBottom: '6px', paddingLeft: '3px'}}>Domínio ou subdomínio próprio</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={18}>
                                        <InputGroup>
                                            <InputGroup.Addon> <small>https://</small></InputGroup.Addon>
                                            <Input value={formState.prodDomain} placeholder={'Digite seu domínio ou subdomínio customizado'} onChange={(event) => {handleFormChange("prodDomain", event, true)}} />
                                            <InputGroup.Addon> <Icon icon="unlock-alt" style={{color: 'red', marginRight: '10px'}} /> <small style={{color: 'red'}}>SSL Inválido</small></InputGroup.Addon>
                                        </InputGroup>
                                        <FieldValidator field="name" validator={validator} />
                                        </Col>
                                        <Col lg={6} style={{display: 'flex', justifyContent: 'center', alignItems: 'flex-end'}}>  <Button appearance='link'><Icon icon="external-link" style={{marginRight: '5px'}}  />Acessar</Button> </Col>
                                    </Row>
                                    <Row style={{marginTop: '25px'}}>
                                        <Col lg={24}>
                                        <Message showIcon type="info" description={<>
                                        <Row>
                                            <Col lg={24}>
                                                <strong>Como apontar um domínio?</strong>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={24}>
                                                <p>Para personalizar o seu domínio, configure um apontamento do tipo <strong>CNAME</strong> com o seu domínio (@), www ou subdomínio para o destino <strong>ead-fe.D4Pay.app</strong>, e após aguardar o tempo de propagação, você poderá acessar seu novo domínio e emitir o certificado SSL.</p>
                                            </Col>
                                        </Row>
                                        </>} />
                                        </Col>
                                    </Row>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </> : null
            }

            {
                tab === 5 ? <>
                    <Row>
                        <Col lg="8" xs="24">
                            <h5 style={{paddingBottom: '10px', marginTop: '25px'}}>Videos Curtos</h5>
                            <p>Adicione videos cursos no formato Tiktok e Reels na sua escola aumentando o engajamento dos alunos no seu conteúdo.</p>
                        </Col>
                        <Col lg="16" xs="24">
                            <Card>
                                <FlexboxGrid justify="start">
                                    <Col lg="6">
                                        <div onClick={()=>setShortVideoModalState({...shortVideoModalState, open: true})} style={{width: '100%', height: '33vh', border: '3px dashed #ccc', cursor: 'pointer', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}>
                                            <div><Icon style={{color: '#3498ff'}} icon="plus-circle" size='3x'></Icon></div>
                                            <div><span style={{fontWeight: 'bold', color: 'rgba(0,0,0,0.5)'}}>Adicionar vídeo</span></div>
                                        </div>
                                    </Col>
                                    {
                                        formState?.shortVideos?.sort((a, b) => a?.updatedAt - b?.updatedAt)?.map(shortVideo => {
                                            console.log('shortVideo', shortVideo)
                                            return <Col lg={6}>
                                                <div style={{border: '3px dashed #3498ff', width: '100%', height: '33vh', background: `url(${getVideoPreviewUrl(shortVideo?.videoSource?.videoId)})`, backgroundSize: 'cover'}}>
                                                    <Row style={{
                                                            height: '100%',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center'
                                                        }}>
                                                        <Col lg={24} style={{display: 'flex', justifyContent: 'center'}}>
                                                            <div className="thumb-button" onClick={()=>removeShortVideoToSchool(shortVideo)}><Icon style={{color: 'white'}} size='lg' icon="trash" /></div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <div style={{marginTop: '0.3rem'}}>
                                                    <h6>{shortVideo?.description}</h6>
                                                </div>
                                            </Col>
                                        })
                                    }
                                </FlexboxGrid>
                            </Card>
                        </Col>
                    </Row>
                    <GenericModal
                        handleApply={() =>{addShortVideoToSchool(shortVideoModalState?.data)}}
                        loading={shortVideoModalState?.loading}
                        applyLabel={<><Icon icon="save"/> Adicionar</>}
                        closeLabel="Cancelar"
                        color="blue"
                        modalState={shortVideoModalState.open} 
                        closeModal={() => setShortVideoModalState(defualtShortVideoModalState)} 
                        title="Adicionar vídeo curto"
                    >
                        <Row>
                            <Col lg={24}>
                                <VideoUpload 
                                title={shortVideoModalState?.data?.name} 
                                uploadCallbackFn={(response)=>{
                                    console.log('uploadCallbackFn', response)
                                    createShortVideo(response)
                                    // setShortVideoModalState({...shortVideoModalState, data:{...shortVideoModalState.data, videoSourtce: response}})
                                }} /> 
                            </Col>
                        </Row>
                        <Row style={{margin: '5px'}}>
                            <Col>
                                <p style={{marginBottom: '6px', paddingLeft: '3px'}}>Descrição</p>
                                <InputGroup>
                                    <Input value={shortVideoModalState?.data?.description} componentClass="textarea" rows={3} onChange={(event) => {setShortVideoModalState({...shortVideoModalState, data:{...shortVideoModalState.data, description: event}})}} />
                                </InputGroup>
                            </Col>
                        </Row>
                    </GenericModal>
                </> : null
            }
            {
                tab === 6 ? <>
                    <Row>
                        <Col lg="8" xs="24">
                            <h5 style={{paddingBottom: '10px', marginTop: '25px'}}>Tab6</h5>
                            <p>Description.</p>
                        </Col>
                        <Col lg="16" xs="24">
                            <h3>Hello tab6</h3>
                        </Col>
                    </Row>
                    
                </> : null
            }

            <Row>
                <Col lg={12}>
                    <div style={{padding: '15px 5px', display: 'flex', justifyContent: 'flex-start'}}>
                            {
                                id ? <Button 
                                    disabled={buttonLoading}
                                    appearance="primary" 
                                    color="red"
                                    onClick={() => {}}
                                >Desativar Escola</Button> : null
                            }
                            
                    </div>
                </Col>
                <Col lg={12}>
                    <div style={{padding: '15px 5px', display: 'flex', justifyContent: 'flex-end'}}>
                            <Button 
                                loading={buttonLoading}
                                appearance="primary" 
                                onClick={() => handleSaveClick()}
                            >{ id ? 'Salvar' : 'Criar' } Escola</Button>
                    </div>
                </Col>
            </Row>

    </>
}
export default AddSchoolPage;