import React, { useState } from 'react';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-css';
import "ace-builds/src-noconflict/theme-monokai";

import "./CodeEditor.css"


const FormCodeEditor = (props) => {
const [code, setCode] = useState(props.code || null);

const handleChange = async (value) => {
    if(props.onCodeChange)
        props.onCodeChange(value)
    setCode(value);
};

return <AceEditor
            mode="css"
            theme="monokai"
            value={code}
            placeholder="Insira código CSS.aqui"
            onChange={handleChange}
            showPrintMargin={false}
            showGutter={true}
            highlightActiveLine={true}
            width='100%'
            setOptions={{
                useWorker: false,
                showLineNumbers: true,
                tabSize: 2,
            }}
            style={{ flex: 1 }}
        />
};

export default FormCodeEditor;
