
import React, {useState} from 'react'
import { Checkbox, CheckboxGroup } from 'rsuite';


import orderbump_arrow from '../assets/svg/orderbump_arrow.svg'

const OrderBump = (props) => {
    const { metadata } = props

    const [acceptOrderBump, setAcceptOrderBump] = useState(false);

    const toggleCheckbox = () => {
        setAcceptOrderBump(!acceptOrderBump)
    }

    return  <div className="orderBump-border" onClick={props.previewMode ? toggleCheckbox : props.toggleAction } style={{...props.style}}>
        <div className="orderBump-header">
            {metadata?.callToAction}
        </div>
        <div className="orderBump-body">
            <div className="orderBump-arrow">
                <img src={orderbump_arrow} width="45px"/>
            </div>
            <div className="orderBump-offer">
                <Checkbox checked={props.previewMode ? acceptOrderBump : props.checkedState}></Checkbox>
                <p><span style={{color: 'red', textDecoration: 'underline'}}>{metadata.title}:</span> {metadata.description} - R${`${metadata.offer ? metadata.offer.price.toFixed(2).replace(".", ",") : "XX,XX"}`}</p>
            </div>
        </div>
    </div>
}

export default OrderBump;