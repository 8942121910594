import {Row, Col} from "rsuite"

const LoadingTable = () => {
    return <>
        <div className="card-table-row list-responsive">
            <div className="rs-col-md-8 rs-col-xs-8"><span className="skeleton-box" style={{width:'151px'}}></span></div>
            <div className="rs-col-md-6 rs-col-xs-6"><span className="skeleton-box" style={{width:'159px'}}></span></div>
            <div className="rs-col-md-6 rs-col-xs-6"><span className="skeleton-box" style={{width:'200px'}}></span></div>
            <div className="rs-col-md-3 rs-col-xs-3"><span className="skeleton-box" style={{width:'85%'}}></span></div>
            <div className="rs-col-md-1 rs-col-xs-1"><span className="skeleton-box" style={{width:'52%'}}></span></div>
        </div>
        <div className="card-table-row list-responsive">
            <div className="rs-col-md-8 rs-col-xs-8"><span className="skeleton-box" style={{width:'130px'}}></span></div>
            <div className="rs-col-md-6 rs-col-xs-6"><span className="skeleton-box" style={{width:'95px'}}></span></div>
            <div className="rs-col-md-6 rs-col-xs-6"><span className="skeleton-box" style={{width:'69%'}}></span></div>
            <div className="rs-col-md-3 rs-col-xs-3"><span className="skeleton-box" style={{width:'62%'}}></span></div>
            <div className="rs-col-md-1 rs-col-xs-1"><span className="skeleton-box" style={{width:'59%'}}></span></div>
        </div>
    </>
}

export default LoadingTable